@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');

h1 {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: xx-large;
    color: #0081C9;
    margin-top: 0px;
    text-align: left;
}

h4 {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: large;
    color: #0081C9;
    margin-top: 25px;
    margin-bottom: 15px;
    text-align: left;
}

h3 {
    margin-top: 45px;
    font-family: Nunito;
    font-weight: 600;
    font-size: large;
    color: #7B7485;
}

@media all and (min-width:992px) {
    .nkp-menu-item {
        width: auto;
    }

    .nkp-menu-item-text {
        font-size: small;
    }

    .nkp-submenu-item {
        font-size: small;
    }

    .nkp-submenu-item-text {
        font-size: medium;
    }

    .nkp-user-profile {
        text-align: right;
    }

    .nkp-action-button {
        justify-content: flex-end;
    }

    .nkp-footer-logo {
        justify-content: start;
    }

    .nkp-footer-section {
        margin-left: 3rem;
    }
}
  
@media all and (max-width: 991px) {
    .nkp-menu-item {
        width: 100%;
    }

    .nkp-menu-item-text {
        font-size: x-large;
        line-height: 2.0em;
    }

    .nkp-submenu-item {
        white-space: normal !important;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }

    .nkp-submenu-item-text {
        font-size: large;
    }

    .nkp-user-profile {
        text-align: left;
        margin-left: -10px;
    }

    .nkp-action-button {
        text-align: center !important;
        justify-content: center;
    }

    .nkp-footer-logo {
        justify-content: center;
        text-align: center !important;
    }

    .nkp-footer-section {
        margin-left: 0rem;
    }
}

@media all and (min-width: 991px) and (max-width: 1125px) {
    .nkp-user-profile {
        font-size: 0px;
    }
}

.icon-overlay {
    font-family: Nunito;
    font-weight: 800;
    font-size: 1.5rem;
    color: #FFFFFF;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.btn {
    border-radius: 50px;
}

.nkp-box-shadow {
    background: #FFFFFF;
    box-shadow: 2px 6px 10px 3px rgba(0, 85, 121, 0.5);
    border-radius: 15px;
}

.nkp-search-box {
    border-radius: 50px;
    border-color: #0081C9;
    border-style: solid;
    border-width: 1px;
}

.nkp-textarea {
    border-radius: 15px;
    border-color: #0081C9;
    border-style: solid;
    border-width: 1px;
}

.nkp-textarea-prepend {
    display: flex;
    align-items: flex-start;
}

.nkp-textarea-invalid {
    background-color: #ffdbd9;
    border-radius: 15px;
    border-color: #FF0000;
    border-style: solid;
    border-width: 1px;
}

.nkp-search-box-invalid {
    background-color: #ffdbd9;
    border-radius: 50px;
    border-color: #FF0000;
    border-style: solid;
    border-width: 1px;
}

.nkp-checkbox-invalid {
    background-color: #ffdbd9;
    border-color: #FF0000;
}

div#nkp-support div {
    margin-top: 5px;
}

div#nkp-support label {
    margin-top: 3px;
}

.table thead th {
    border-color: #FFFFFF;
    background-color: #0081C9;
    color: #FFFFFF;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 85, 121, 0.05);
}

.nkp-dark-box-1 {
    background: rgba(0, 0, 0, .8);
}

.nkp-document-plate {
    background: #C2E3F3;
    border-radius: 15px;
    min-height: 5em;
    font-size: small;
    padding: 15px;
    margin: 15px;
}

.nkp-kennel-plate-kennel {
    background: #FFFFFF;
    box-shadow: 2px 6px 15px 5px rgba(0, 85, 121, 0.5);
    border-radius: 15px;
    min-width: 250px;
    font-size: medium;
}

.nkp-kennel-plate-prefix {
    background: #C2E3F3;
    box-shadow: 2px 6px 15px 5px rgba(0, 85, 121, 0.5);
    min-width: 250px;
    border-radius: 15px;
    font-size: medium;
}

.nkp-dog-plate {
    background: #FFFFFF;
    border-radius: 15px;
    border-width: 1px;
    border-color: #0081C9;
    border-style: solid;
    font-size: medium;
}

.custom-file-upload {
    background-color: #0081C9;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.custom-file-upload {
    background-color: #0081C9;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.nkp-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.nkp-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    height: calc(1.5em + 0.75rem);
    line-height: 1.5;
    content: "Browse";
    border-left: inherit;
    background-color: #0081C9;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.nkp-file-label-invalid {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.nkp-file-label-invalid::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    height: calc(1.5em + 0.75rem);
    line-height: 1.5;
    content: "Browse";
    border-left: inherit;
    background-color: #dc3545;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.nkp-date-capital {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 87px;
    text-align: center;
    color: #0081C9;
}

.nkp-date-rest {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 49px;
    text-align: center;
    color: #0081C9;
}

.nkp-sports-plate {
    background: #FFFFFF;
    border-radius: 15px;
    border-color: #0081C9;
    border-width: 1px;
    border-style: solid;
    font-size: medium;
}

.nkp-sports-plate-active {
    background: #FFFFFF;
    border-radius: 15px;
    border-color: #0081C9;
    border-width: 1px;
    border-style: solid;
    box-shadow: 2px 6px 15px 5px rgba(0, 85, 121, 0.5);
    font-size: medium;
}

.nkp-sports {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 49px;
    text-align: center;
    color: #0081C9;
}

.nkp-sports-overlay {
    position: absolute;
    min-width: 350px;
    min-height: 200px;

    background: rgba(194, 227, 243, 0.9);
    box-shadow: 2px 6px 15px 5px rgba(0, 85, 121, 0.5);
    border-radius: 15px;

    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;

    z-index: 10;
}

.nkp-sports-overlay-date {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-align: center;
    color: #0081C9;
}

.nkp-board-name {
    font-size: small;
    font-weight: 900;
    text-align: center;
}